import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`8:00 am sharp when sunbeams start circling his windows. They burst through the glass, speeding across the room and landing oh his closed lids. Their slightest touch that tingles till he willingly opens his eyes. He lays there as if paralyzed, only squinting his eyes from the burning light in pain. If he could he would never get up, but the thought of hot freshly brewed coffee always satisfied his morning mindset. He starts folding the grainy linen bed sheets, hesitantly getting out of bed. His feet press into the cold wood floors, he shudders and falls bad into bed. Yet another tiny splinter. He rolls around to the other side of the bed, holding his foot up in the air and messing up his folded sheets. He carefully reaches and opens a sparkling golden clasp on his 1970 wooden nightstand, pulling out the drawer and rustling around through all the small crowded objects.`}</p>
    <p>{`His hand, as big as half of the drawer reaches in deep and pulls out a pair of small shiny metal tweezers. He proceeds to press his big cold fingers on the heel surrounding the splinter and tugging it out with the tweezers. Satisfaction. He throws the tweezers back in the drawer and shuts it closed. Once again he plops into bed, rolling back to the other side and getting up. He swiftly moves to the bathroom, grabbing his white silky robe and throwing it onto the sink. He turns on the tap and lets the water run for a minute hoping it will get warmer. Disappointed, he gets in. Cold droplets of water fall down his face. Slowly they flow, soaking his body in ice-cold water. He turns the tap and gets out, swiftly throwing his robe up and wraps it around his body. Breakfast awaits. He stomps out into the kitchen where the sun lights up the room. Blinded by the light he goes to close the curtains. His gaze latches onto the big streets of NYC.`}</p>
    <p>{`His gloomy eyes stare into the distance, catching every face and every bit hidden in the tiniest crevices of the city. He pulls the curtains and tumbles to the fridge.`}</p>
    <p>{`Leftovers. Sighing, he grabs the coarse styrofoam boxes and places them on the counter. He opens the drawer and takes out the one remaining rusty fork. Joy sparks his face. He races towards the kettle and starts boiling the water. Reaching into the cupboard he pulls out a fresh bag of coffee grounds. The grainy rich smell makes him forget about everything. The grounds land into the cup. He slowly pours steamy hot water over them, stirring everything together. He looked around and caught onto the clock, ticking. His eyes widen. He takes the hot coffee and chugs it, feeling how his throat begins to sizzle and burn.`}</p>
    <p>{`With immense pain. He runs out into his bedroom and slides the robe off his body, the silky fabric flowing down to the ground. He quickly throws on his black t-shirt and his old scratchy grey jeans. He makes a run to the door and grabs his coat. He slides into his obnoxiously loud loafers and shoves the book bag under his arm, locks the door and runs outside. His loafers make a loud flapping sound his whole route to work. 5 meters away he sees Ed outside the shop, leaning on the wall and lightly tapping his dusty cigarette. "John, there you are!" yells Ed, throwing the cigarette on the ground and pushing it into the cement with his shoe. "Only thirty minutes late" he chuckles sarcastically. "I'm sorry, I didn't mean to. You know, I get caught up and lose track of time" John says sobbingly.`}</p>
    <p>{`Ed shrugs, "C'mon buddy we both know you had your chances, this was the last straw. We had a whole conversation on you missing your shifts!". "Please I didn't mean to, I need this job" Ed leans in and whispers in John's ear. "I've had enough, I'm tired of listening to your sad excuses. He lightly pushes him on the shoulder and slowly walks up to the shop.`}<br parentName="p"></br>{`
`}{`"Your stuff is on the counter, I want you gone in thirty minutes" He smirks and walks in. I stood there as if emotions overflowed my body and took away my ability to move. I always liked Ed he was forgiving and had the greatest sense of humor. He had kids and a wife at home I don't know who would do such a thing. John looked up at Detective Spielberg. She stared right into his eyes and asked: "Mister Porter, so you're telling me you don't know who murdered Edwin Johnson on the third of July when his body was found next to your home?"`}</p>
    <p>{`"I'm sorry ma’am, but as I stated earlier I wasn't home all night and spent time grieving at the 99 Pub downtown"`}</p>
    <p>{`"Mister Porter, those are just empty statements that we have no evidence of. Unfortunately, some of our gathered information tells us otherwise about your location last night. But that's enough for today, we'll call you first thing in the morning tomorrow. Maybe you'll think, there might be something that you're not telling us". She stood up, shook my hand and slowly exited the room. I felt nauseous. Sitting in a cubic room, with nothing but a table and two chairs opposite to each other. My hands went numb and all I could picture were the actions done that day. The day I killed a person, the guy that left me with no job, dear Ed, Edwin Johnson.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      